import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {

  constructor(
    public http: HttpClient,
  ) { }

  async getProducts(): Promise<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('msal.idtoken')}`);
    return await this.http.get<any>(`${environment.naranjaDevelopersBFF.getProducts}`, { headers })
    .pipe(take(1)).toPromise();
  }

  async getProductsSandbox(): Promise<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('msal.idtoken')}`);
    return await this.http.get<any>(`${environment.naranjaDevelopersBFF.getProductsSandbox}`, { headers })
    .pipe(take(1)).toPromise();
  }

}
